@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&display=swap');
@import url(https://fonts.googleapis.com/css?family=Roboto:500);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}
body {
  font-family: 'Poppins', sans-serif;
  /* transform: scale(0.9); */
}
h3 {
  font-family: 'Jost', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  padding-top: 100px;
}

/* HEADER */
.wrapper-header {
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0; left: 0;
  padding: 0 20px;
  width: 100vw;
  height: 90px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.wrapper-header img {
  width: 300px;
  margin-left: 30px;
  /* border: 1px solid red; */
}
.wrapper-header .menu {
  width: fit-content;
  /* border: 1px solid red; */
  height: 100%;
}
.wrapper-header .menu button {
  height: 100%;
  border: none;
  padding: 0 20px;
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
  /* background-color: white; */
}
.wrapper-header .menu button:hover {
  color: white;
  background-color: #0089BF;
}

.btnSelected {
  background-color: #0089BF;
  color: white;
}
.notSelected {
  background-color: white;
  color: gray;
}

.mobile-header {
  display: none;
}
.btnMobileMenu {
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: #0089BF;
  display: none;
}

.mobileMenu {
  position: fixed;
  top: 45px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 20px 20px;
  z-index: 998;
}
.mobileMenu a, .mobileMenu button {
  font-size: 20px;
  height: 50px !important;
  border-radius: 5px;
  border: none;
}

#showing {
  animation: showingMobileMenu 0.5s;
}
#hidding {
  display: none;
  animation: hiddingMobileMenu 1s;
  top: -100%;
}
@keyframes showingMobileMenu {
  from {
    display: none;
    top: -100%;
  }
  to {
    display: block;
    top: 45px;
  }
}
@keyframes hiddingMobileMenu {
  from {
    top: 45px;
    display: block;
  }
  to {
    top: -100%;
    display: none;
  }
}
.closeMenuBtn {
  animation: closeMenuBtn 1s;
}
@keyframes closeMenuBtn {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* HERO */



.wrapper-hero {
  width: 80vw;
  height: 80vh;
  max-height: 500px;
  margin: 40px auto; 
  border-radius: 20px;
  transition: 1s;
  
}

.hero-content {
  display: grid;
  grid-template-columns: auto 100px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.33);
}

.hero-content img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 5px solid white;
  border-radius: 10px;
}
.hero-content img :hover {
  transform: scale(1.2);
}
#location{
  font-size: 20px;
  text-shadow: none;
  margin-top: 10px;
  text-shadow: 2px 2px 5px #00000076;
}

.wrapper-hero  h2 {
  text-align: left;
  font-size: 50px;
  color: white;
  text-shadow: 2px 2px 5px #00000076;
  margin-bottom: 0px;
  max-width: 80%;
}
.wrapper-hero  p {
  text-align: left;
  font-size: 30px;
  font-weight: 500;
  color: white;
  text-shadow: 2px 2px 5px #00000076;
  width: 80%;
}
.hero-text {
  height: 90%;
}

.wrapper-hero button {
  display: block;
  border: none;
  width: 250px;
  height: 45px;
  font-size: 20px;
  font-weight: 600;
  background-color: #0089BF;
  color: white;
  border-radius: 5px;
  margin-left: 85%;
}
.wrapper-hero button:hover {
  background-color: white;
  color: #0089BF;
}


.wrapper-hero .navigation {
  display: flex;
  justify-content: space-between;
  width: 100px;
  margin-top: 50%;
}
.wrapper-hero .navigation button {
  background-color: transparent;
  border: none;
  font-size: 40px;
  text-shadow: 2px 2px 5px #00000076;
  color: white;
}

.carousel {
  width: 80%;
  height: 550px;
  margin: auto;
  display: inline-flex;
  flex-wrap: nowrap;
  overflow-X: scroll;
  flex-direction: rows;
  transition: 2s;
}
.arrows  {
  display: inline-flex;
  background-color: transparent;
  border: none;
  font-size: 40px;
  margin: 10px;
  color: #0089BF;
}

.carousel .property {
  /* animation: scroll 5s linear infinite; */
}

@keyframes scroll {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-2650px);
  }
  100% {
    transform: translateX(0px);
  }
}



.carousel .property .name-price{
  

}


.filter {
  display: flex;
  max-width: 85%;
  margin: 20px auto;
  /* border: 1px solid lightgray; */
  text-align: right;
  border-radius: 10px;
  justify-content: space-between;
}
.filter h2 {
  font-size: 30px;
  color: #072A40;
}
.filter select{
    color: gray;
    border: 1px solid lightgray;
    border-radius: 10px;
    background-color: transparent;
    padding: 10px 60px;
    outline: none;
    font-size: 20px;
    appearance: none;
    text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}



/* INFORMATION */

.wrapper-information {
  margin: 50px auto;
  width: 85%;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}
.wrapper-information img {
  display: block;
  width: 90%;
  margin-right: auto;
}

.wrapper-information #right {
  padding: 30px;
} 
.wrapper-information h2 {
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 10px;
  text-align: left;
  color: #072A40;;
  line-height: 40px;
}
.wrapper-information p {
  text-align: left;
  font-weight: 300;
  font-size: 20px;
}

.generalBtn {
  display: block;
  margin: 20px 0 auto;
  background-color: #0089BF;
  color: white;
  padding: 10px 30px;
  font-size: 20px;
  border: none;
  border-radius: 10px;
}
.generalBtn:hover {
  background-color: #096387;
}
a {
  text-decoration: none;
}


/* REVIEWS */
.wrapper-reviews {
  background-color: #072A40;
  padding: 70px 10%;
}
.wrapper-reviews .two-col {
  display: grid;
  grid-template-columns: auto auto;
  gap: 50px;
}
.wrapper-reviews .two-col h3 {
  font-size: 25px;
  text-align: left;
  color: white;
}
.wrapper-reviews .two-col p {
  font-size: 14px;
  text-align: right;
  color: white;
  margin-bottom: 50px;
}

.wrapper-reviews .three-col {
  display: flex;  
  justify-content: space-between;
}
.wrapper-reviews .review {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  color: white;
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  margin: 5px;
}
.review p {
  margin-top: 10px;
}
.wrapper-reviews hr {
  margin: 10px -3px;
  border: 1px solid lightgray;
}

.person-stars {
  display: flex;
  justify-content: space-between;
  font-weight:600;
}

.wrapper-whyRecommend {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 80%;
  margin: auto;
  padding: 60px 0;
  align-items: center;
}
.wrapper-whyRecommend h2 {
  color: #072A40;
  font-size: 40px;
  text-align: left;
}
.wrapper-whyRecommend p {
  text-align: left;
  font-size: 15px;
}
.wrapper-whyRecommend img {
  display: block;
  object-fit: cover;
  width: 90%;
  height: 600px;
  margin-left: auto;
  border-radius: 10px;
  transform: zoom(1.5);
}

.wrapper-whyRecommend h3 {
  color: #072A40;
  text-align: left;
  margin-top: 20px;
}
.why {
  font-size: 13px;
}



/* FOOTER */
.wrapper-footer {
  background-color: #072A40;
  padding: 60px 2.5%;
  margin: auto;
  display: grid;
  grid-template-columns: 40% auto;
  color: white;
  align-items: center;
  font-size: 15px;
}
.wrapper-footer img {
  width: 300px;
  margin-bottom: 10px;
}

.newsLetter {
  max-width: 500px;
  display: grid;
  grid-template-columns: auto auto;
  background-color: white;
  height: 50px;
  border-radius: 5px;
  margin: 0 0 20px auto;
  align-items: center;
  padding: 3px;
}
.newsLetter input {
  border: none;
  outline: none;
  padding-left: 10px;
  height: 100%;
  background-color: transparent;
}
.newsLetter button {
  display: block;
  background-color: #0089BF;
  color: white;
  padding: 5px 0;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  height: 100%;
}
.newsLetter button:hover {
  background-color: #096387
}

#noSpam {
  font-size: 15px;
  margin-top: 20px;
  color: gray;
  max-width: 400px;
  text-align: right;
  margin: 5px 0 50px auto;
}

.wrapper-footer .menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: right;
  width:90%;
  margin-right: 0;
  margin-left: auto;
}

.wrapper-footer #right {
 display: flex;
  width: 60%;
  margin-left: auto;
}
.wrapper-footer #right p{
  text-align: center;
}
.wrapper-footer .menu a {
  display: block;
  margin: 10px auto;
  background-color: transparent;
  border: none;
  color: gray;
  font-size: 15px;
}
.wrapper-footer .menu a:hover{
  color: #0089BF;
}

.wrapper-footer a {
  display: block;
  color: white;
  margin-bottom: 10px;
  font-size: 15px;
}

/* PROPOERTies  */

.wrapper-properties {
  width:90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.title-properties {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.title-properties h2 {
  color: #072A40;
  font-size: 40px;
}
.title-properties button {
  border: none;
  font-size: 20px;
  color: #0089BF;
  background-color: transparent;
}
.title-properties button:hover {
  color: #096387;
}

.property {
  border: 1px solid lightgray;
  padding: 20px;
  
  display: block;
  width: 350px;
  height:95%;
  
  border-radius: 10px;
  padding: 15px;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
  /* .property:hover{
    background-color: rgb(234, 234, 234);
    margin-bottom: 20px !important;
  } */
.property p {
  text-align: left;
}
.property img {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  object-fit: cover;

}
.property img:hover {

}
.property .nickname {
  font-size: 20px;
  font-weight: 600;
  color: #072A40;
  padding-left: 5px;
  height: 60px;
}
.property .price {
  position: relative;
  top: -40px;
  margin-bottom: -25px;
  width: 100%;
  color:white;
  font-weight: 600;
  backdrop-filter: blur(5px);
  padding: 3px 5px !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-shadow: 2px 2px 5px black;
} 
.property .address {
  margin: 5px 0;
  padding-left: 5px;
  color: gray;
}
.property .description {
  font-size: 12px;
}

.property .capacities-container {
  border: 1px solid lightgray;
  padding: 5px 10px;
  text-align: center;
  margin: 2px;
  border-radius: 5px;
  color: gray;
}

.property .capacities {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin: 10px auto;
}

.property .quantity {
  display: inline;
  font-size: 20px;
}

.icon {
  font-size: 20px;
  margin: 0px 5px -5px -5px;
  color: #0089BF;
}

.property h2 {
  display:inline;
  font-size: 20px;
  text-align: left;
}
.property #description {
  font-size: 15px;
}
.property .header {
  display: flex;
  justify-content: space-between;
  margin: auto;
  z-index: 999;
}
.property button {
  width: 100%;
  background-color: #0089BF;
  border-radius: 5px;
  height: 40px;
  border: none;
  margin: 10px 0;
  color: white;
  font-size: 20px;
  font-family: 'Jost', sans-serif;
}
.property button:hover {
  background-color: #096387;
}

#price {
  font-size: 15px;
  color: #0089BF;
  border: 1px solid #0089BF;
  padding: 3px 6px;
  border-radius: 5px;
  z-index: 998;
}


/* BOOKING */
/* .wrapper-booking {
  background-color: white;
  position: fixed;
  bottom: 0; left: 50%;
  transform: translateX(-50%);
  width: 80%;
  min-width: 1100px;
  height: 800px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0px 0px 89px 2000px rgba(0,0,0,0.3);
  animation: booking 0.7s;
} */

@keyframes booking {
  from {
    bottom: -800px;
    box-shadow: 0px 0px 89px 2000px rgba(74, 74, 74, 0);
  }
  to {
    bottom: 0px;
    box-shadow: 0px 0px 89px 2000px rgba(0,0,0,0.3);
  }
}

.btnClose {
  display:block;
  margin: 15px auto 0 15px;
  background-color: transparent;
  border: none;
  font-size: 40px;
  color: gray;
  position: absolute;
  top: 0;
}

.wrapper-booking {
  display: grid;
  grid-template-columns: 70% auto;
  padding: 20px;
  max-width: 1600px;
  margin: auto;
  /* height: 100vh; */
}

.wrapper-booking #left {
  padding-right: 20px;
  border-right: 1px solid lightgray;
  overflow-Y: scroll;
  height: 100%;
  padding-bottom: 50px;
}

.wrapper-booking .pictures {
  display: grid;
  grid-template-columns: auto 100px;
  gap: 20px;
  height: 600px;
}

.wrapper-booking img {
  display: block;
  margin: auto;
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.wrapper-booking .galery {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: fit-content;
  overflow-Y: scroll;
  height: 600px;
}

.wrapper-booking .galery img {
  height: 70px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  object-fit: cover;
}

.wrapper-booking .galery img:hover {
  transform:translateY(-3px)
}

.wrapper-booking #left h3 {
  font-size: 30px;
  text-align: left;
  color: #096387;
  margin-top: 10px;
}
.wrapper-booking #left p {
  text-align: left;
  margin-top: 10px;
  font-size: 16px;
}

.wrapper-booking .address {
  color: gray;
  font-size: 20px;
  text-align: left;
}


.wrapper-booking .form {
  padding: 10px;
  /* border: 1px solid lightgray; */
  border-radius: 10px;
  margin-top: 20px;
}

.wrapper-booking .googleSignIn {
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 50px auto;
  /* border: 1px solid lightgray; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 30px;
  align-items: center;
  color: gray;
}
.wrapper-booking .googleSignIn:hover {
  background-color: #0089BF;
  color: white;
}
.wrapper-booking .googleSignIn img{
  height: 35px;
  width: 35px;
  object-fit: cover;
  background-color: white;
  padding: 3px;
}

.wrapper-booking .googleSignIn p{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-left: -50px;
}

.wrapper-booking .googleAuth{
  display: flex;
  flex-direction: column;
  
}
.wrapper-booking .googleAuth h2 {
  text-align: center;
  margin-bottom: 50px !important;
}

.google-btn {
  width: 95%;
  margin: auto;
  height: 50px;
  background-color: #1087ff;
  border-radius: 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  
  text-align: center;
  margin-bottom: 100px;
  background-color: white;
}
.google-btn .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: white;
}
.google-btn .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
}
.google-btn .btn-text {
    text-align: center;
    line-height: 42px;
    color: gray;
    font-size: 16px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
}
.google-btn .btn-text:hover {
    box-shadow: 0 0 6px #4285f4;
}
.google-btn .btn-text:active {
  background: #1669F2;
}




.wrapper-booking .form h3 {
  font-size: 20px;
  font-weight: 500;
  color: #0089BF;
  background-color: white;
  margin-left: 20px;
  width: fit-content;
  padding: 0 10px;
  position: relative;
  top: 15px;
}
.wrapper-booking .form .detail {
  display: grid;
  grid-template-columns: 0px auto;
  border: 1px solid lightgrey;
  align-items: center;
  padding: 10px 0px 5px 5px;
  border-radius: 10px;
  margin-bottom: 0px;
}

.guestsNum {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 15px 15px 10px 25px;
  font-size: 20px;
}
.guestsNum p {
  font-size: 20px !important;
}
.guestsNum .guestsBtns {
  width: fit-content;
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
}
.guestsBtns button {
  width: 80px;
  background-color: #0089BF;
  border: 1px solid lightgray;
  font-size: 25px;
  color: white;
  border-radius: 5px;
}

.capitalized {
  text-transform: capitalize;
}
.lowercased {
  text-transform: lowercase;
}
.wrapper-booking .form input{
  margin: 0;
  border: none;
  font-size: 20px;
  
}


.wrapper-booking h2 {
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  color: #072A40;
  margin: 20px 0;
}
.wrapper-booking input {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 15px 15px;
  border-radius: 10px;
  border: 1px solid lightgray;
  font-size: 15px;
  outline: none;
}
.wrapper-booking .btnForm {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #0089BF;
  color: white;
  font-size: 25px;
  font-weight: bold;
  margin-top: 20px;
}

.bookingConfirmation {
  border-radius: 10px;
  background-color: #0089bfa3;
  padding: 5px 15px 20px 15px;
  color: white;
  text-align: left;
  margin: 20px 0;
}
.bookingConfirmation h2 {
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  padding-bottom: 10px;
  border-bottom: 1px solid white;
}

.wrapper-booking #right {
  padding: 0 20px;
  text-align: left;
  overflow-y: scroll;
}

.wrapper-booking p {
  font-size: 15px;
}


.wrapper-booking #right h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 25px;
}



.wrapper-booking .capacities {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  margin: 20px auto 20px 0;
}
.wrapper-booking .capacities p{
  font-size: 15px;
  border: 1px solid lightgray;
  padding: 10px 20px;
  text-align: center;
  margin-right: 10px;
  border-radius: 5px;
}
.wrapper-booking .capacities p:hover{
  background-color: rgb(232, 232, 232);
}

.wrapper-booking iframe {
  width: 100%;
  height: 300px;
  border: none;
  border-radius: 10px;
}

.confirmationScreen {
  position: fixed;
  top: 0; left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 998;
}
.confirmationScreen p {
  max-width: 700px;
  margin-bottom: 10px;
  font-size: 30px;
  color: gray;
}
.calendarCheckIcon {
  font-size: 100px;
  color: #0089BF;
}
.confirmationScreen a {
  padding: 10px 50px;
  margin-top: 20px;
  background-color: #0089BF;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 20px;
}

/* .allAmenities {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 30px;
} */
/* .amenities {
  font-size: 17px;
  border: 1px solid #0089BF;
  color: #0089BF;
  padding: 4px 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  width: fit-content;
  margin-right: 5px;
} */




.allAmenities {
  display: grid;
  grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
  margin-bottom: 30px;
}
.amenities {
  display: grid;
  grid-template-columns: 20px auto;
  align-items: center;
  color: #0089BF;
  padding: 4px 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  width: fit-content;
  margin-right: 5px;
  text-align: left;
}
.amenitiesIcon, .amenities p {
  display: inline;
  font-size: 15px !important;
}
.amenities p {
  color: gray;
}

.amenitiesIcon {
  margin-top: 8px;
}


.react-calendar {
  min-width: 370px;
  width: 370px;
  max-width: 370px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 40px;
  max-width: 100%;
  background: white;
  line-height: 1.125em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  padding: 20px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 40px;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  /* background-color: #2c3e50; */
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.react-calendar__navigation button {
  background-color: transparent;
  color: gray !important;
  height: 100%;
  font-size: 20px;
  border: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  color: #0089BF !important;
  
}
.react-calendar__navigation button[disabled] {
  /* background-color: #2c3e50; */
}
.react-calendar__month-view__weekdays {
  text-align: center;
  /* text-transform: uppercase; */
  font-weight: 400;
  font-size: 20px;
  /* background-color: #0089BF; */
  color: gray;
}

abbr[title] {
  text-decoration: none;
  font-size: 13px;
  margin-left: -5px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
} */
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 43px;
  text-align: center;
  padding: 5px 10px;
  background: none;
  color: gray;
  /* border: 2px solid white; */
  border: none;
  font-size: 16px;
  border-radius: 10px;
  margin: 2px !important;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.react-calendar__tile:disabled {
  text-decoration:line-through;
  background-color: rgb(244, 244, 244);
  border: 1px solid white
  /* color: red; */
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76 !important;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  /* background: #2c3e50; */
  color: #0089BF;
  border: 1px solid #0089BF;
}


.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

 .prices {
    font-size: 10px;
  }


/* ==========  MAP ========== */

.wrapper-map {
  position: sticky;
}

/* ==========  LOADER ========== */
.wrapper-loader {
  position: fixed;
  top: 90px; left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  animation: wrapper-loader 2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10% 50px;
}
.calendarIcon {
  font-size: 220px;
  color: lightgray;
}

.bouncingCalendar {
  animation: bounce 1s ease infinite;
}

@keyframes bounce {
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}


.wrapper-loader h2 {
  font-size: 30px;
  font-weight: 400;
  color: lightgrey;
}

@keyframes wrapper-loader {
  from {
    display: block;
  } 
  to {
    display: none;
  }
}

.loading-bar {
  display: block;
  margin: auto;
  width: 50%;
  height: 15px;
  background-color: #eee;
  border-radius: 20px;
}

.loading-bar-progress {
  height: 100%;
  border-radius: 20px;
  background-color: #0089BF;
  animation: loading-bar-animation 2s linear forwards;
}

@keyframes loading-bar-animation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}




/* POPUP BOOKING */

.popup-booking {
  position: fixed;
  top: 90px; left:0;
  width: 100vw;
  height: calc(100vh - 90px);
  background-color:  rgba(0, 0, 0, 0.394);
  justify-content: right;
}

.wrapper-confirmation {
  position: fixed;
  top: 90px; right: 0;
  width: 500px;
  height: 100%;
  background-color: white;
  padding: 20px;
  overflow-y: scroll;
  animation: confirmation 0.5s;
}
@keyframes confirmation {
  from {
    right: -500px;
  }
  to {
    right: 0px;
  }
}


.wrapper-confirmation h1 {
  font-size: 40px;
  color: #0089BF;
  font-weight: bold;
}

.wrapper-confirmation .btnBack {
  display: none;
}

.wrapper-confirmation hr{
  margin: 20px 0;
}
.reservation-details {
  margin-bottom: 100px;
}
.reservation-details h1 {
  margin-bottom:10px;
  line-height: 40px;
}
.reservation-details h2 {
 margin-top: 0px !important;
 margin-bottom: 15px !important;
 color: gray;
}
.wrapper-confirmation h3 {
  font-size: 20px;
  font-weight: 500;
  color: #0089BF;
  background-color: white;
  margin-left: 20px;
  width: fit-content;
  padding: 0 10px;
  position: relative;
  top: 15px;
}
.wrapper-confirmation .detail {
  display: grid;
  grid-template-columns: 50px auto;
  border: 1px solid lightgrey;
  align-items: center;
  padding: 18px 15px 10px 15px;
  border-radius: 5px;
  margin-bottom: 0px;
}
.wrapper-confirmation #fullName {
  text-transform: capitalize;
}
.icon-confirmation {
  color: #0089BF;
  font-size: 25px;
  margin-left: 5px;
}
.wrapper-confirmation .detail p {
  color: gray;
  font-size: 20px;
}

.summary {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 15px 10px;
  overflow-y: scroll;
  height: fit-content;
  max-height: 200px;
  margin-bottom: 10px;
}
.summary-row {
  display: grid;
  padding: 5px 5px;
  grid-template-columns: auto 100px; 
  border-bottom: 1px solid lightgray;
  color: gray;
}
.summary-row:hover {
  border-radius: 5px;
  border-bottom: none;
  background-color: #0089BF;
  color: white;
}
.summary-row p {
  font-size: 20px;
}
/* .totals {
  width: 200px;
  display: flex;
  margin-top: 20px;
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 100px;
} */




.card-details {
  border: 1px solid lightgrey;
  align-items: center;
  padding: 18px 15px 10px 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.FormGroup {
  width: 100%;
	padding: 0;
	border-style: none;
	will-change: opacity, transform;
  /* border: 1px solid lightgray; */
}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
}

#btnPay {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #0089BF;
  font-size: 20px;
}

.paymentLoader {
  position: fixed;
  top: 90px; right: 0;
  width: 500px;
  height: 100%;
  background-color: white;
  z-index: 999;
  padding-top: 15%;
}


.paymentLoader p {
  display: block;
  font-size: 20px;
  color: gray;
  text-align: center;
}
.paymentLoader img {
  display: block;
  width: 90%;
  height: 300px;
  box-shadow: none;
}

.paymentConfirmation {
  position: fixed;
  top: 90px; right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 500px;
  height: 100%;
  background-color: white;
  padding: 20px;
  overflow-y: scroll;
  animation: confirmation 0.5s;
}
.paymentConfirmation p {
  font-size: 20px;
  color: gray;
}

.iconCheck {
  margin-top: 30%;
  color: #00bf19;
  width: 100px;
  font-size: 100px;
  animation: rollIcon 2s;
}

@keyframes rollIcon {
  from {
    transform: translate(rotate 0%);
  }
  to {
    transform: translate(rotate 360%);
  }
}

#failed {
  color: red;
  font-size: 15px;
  margin-bottom: 10px;
}



/* ======  CONTACT PAGE  ====== */
.contact-grid2 {
  display: grid;
  grid-template-columns: auto 45%;
  gap: 40px;
  align-items: center;
  padding: 30px 10%;
  text-align: left;
}

.contact-grid2 h2{
  font-size: 40px;;
}
.contact-grid2 p {
  max-width: 80%;
}
.contact-grid2 form {
  padding: 20px;
  border-radius:10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.inputs-form {
  margin-top: 15px;
  font-size: 20px;
}
.inputs-form p {
  font-weight: bold;
  color: gray;
  margin-bottom: 5px;
}
.in-form {
  width: 100%;
  border: none;
  border-bottom: 3px solid lightgray;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 10px 5px;
  outline:none;
  font-size: 20px;
  margin-bottom: 10px;
}
.grid-2 {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}
.inputs-form textarea {
  width: 100%;
  resize: none;
  font-family: 'Poppins', sans-serif;;
}

.btn-form {
  display: block;
  text-align: center;
  line-height: 45px;
  height: 45px;
  background-color: #072A40;
  color: white;
  border: none;
  font-size: 20px;
  border-radius: 10px;
}
.btn-form:hover{
  background-color: #096387;
}

.hereForYou {
  background-color: #072A40;
  padding: 100px 10%;
  margin-top: 40px;
}

.hereForYou h2 {
  color: white;
  text-align: left;
}
.hereForYou p {
  text-align: left;
  color: lightgray;
  max-width: 500px;
}
.hereForYou .contact-methods {
  max-width: 1000px;
  margin: auto;
  margin-top: -80px;
}
.hereForYou .method {
  width: 400px;
  height: 80px;
  border-radius: 10px;
  color: white;
  background-color: #234153;
  display: flex;
  align-items: center ;
  justify-content: center;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.hereForYou .method:hover{
  box-shadow: #0089bf38 0px 0px 20px;
  border: 1px solid gray;
}

.hereForYou .method {
  color: white;
  font-weight: 600;
  text-align: center;
  font-size: 20px;

}
.hereForYou #mail, .hereForYou #phone {
  margin-left: auto;
}
.hereForYou #support {
  margin:50px auto 50px 0;
}

.contact-icon {
  color: #74CEC4;
  margin-right: 20px;
  font-size: 30px;
}



/* ======= SERVICES PAGE ======== */
.wrapper-services {
  padding: 0 10%;
}
.wrapper-services .introduction {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  height: 550px;
}
.wrapper-services .introduction img {
  object-fit: cover;
}

.wrapper-services .introduction #left{
  text-align: left;
}

.wrapper-services .introduction h2 {
  color: #072A40;
  font-size: 35px;
}
.wrapper-services .introduction p {
  color: gray;
}
.btnServices  {
  width: 200px;
  height: 40px;
  background-color:#0089BF;
  color: white;
  border-radius: 10px;
  border: none;
  margin-top: 20px;
  font-weight: 500;
}
.btnServices:hover  {
  background-color: #096387
}

.wrapper-services .our-services {
  margin-bottom: 30px;
}

.wrapper-services .our-services h2 {
  color: #072A40;
  font-size: 35px;
  margin-bottom: 20px;
}

.wrapper-services .highlights {
  /* width: 110%;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  gap: 5px;
  justify-content: center;
  margin-top: 100px;
  border:1px solid red; */

  
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  max-width: 100%; /* Adjust max-width as needed */
  position: relative;
  left: 50%;
  transform: translate(-50%);
  padding: 0 10px;
  justify-content: center;
}



.highlights .container {
  background-color: #072A40;
  border-radius: 10px;
  /* width: 280px;
  /* height: 400px; */
  /* display: flex;
  align-items: center;
  /* flex-direction: column; */
  padding: 20px 30px;
  gap: 10px;
  margin: 0 auto; 
  flex: 1 0 280px; 
}

@media screen and (max-width: 1120px) {
  .container {
    flex-basis: calc(25% - 20px); /* Adjust the percentage based on the number of items per row */
  }
}

@media screen and (max-width: 840px) {
  .container {
    flex-basis: calc(33.33% - 20px); /* Adjust the percentage based on the number of items per row */
  }
}

@media screen and (max-width: 560px) {
  .wrapper-services .highlights {
    margin-top: 100px;
  }
  .container {
    flex-basis: calc(50% - 20px); /* Adjust the percentage based on the number of items per row */
  }
}
@media screen and (max-width: 400px) {
  .wrapper-services .highlights {
    margin-top: 100px;
  }
  .container {
    flex-basis: 100%; /* Make items take up full width on smaller screens */
  }
}
.highlights .container h2 {
  color: white;
  font-size: 25px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.highlights .container p {
  color: lightgray;
  font-size: 15px;
  margin-bottom: 30px;
}

.whyChooseUs {
  margin: 70px auto;
}
.whyChooseUs h2 {
  font-size: 40px;
  color: #072A40;
}
.whyChooseUs p {
  color: gray;
  width: 80%;
  margin: auto;
  font-size: 18px;
}


.insights {
  display: flex;  
  flex-wrap: nowrap;
  margin-bottom: 100px; 
}
.insights .container {
  border-radius: 10px;
  padding: 30px;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.insights h2 {
  color: #072A40;
  margin-top: 20px;
}
.insights p {
  color: gray;
  font-size: 18px;
}

.results {
  display: grid;
  grid-template-columns: auto auto;
  gap: 50px;
}
.results img {
  border-radius: 10px;
  object-fit: cover;
}

.results #left {
  margin-right: auto;
}

.results .two-columns {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  margin: auto;
  margin-top: 5px;
}

.results #right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center ;
  padding: 10%;
}

.results .indicators {
  display: flex;
  justify-content: space-between; 
  margin: 20px;
  width: 80%;
} 

.results p {
  font-size: 20px;
  color: gray;
  margin-top: 20px;
}

.results .indicator p {
  font-size: 18px;
}

.results .indicators .indicator {
  width: 100%;
}
#top-indicators{
  margin-top: 80px;
}

.faq {
  margin-top: 100px;
  margin-bottom: 100px;
}
.faq h2 {
  font-size: 40px;
  margin-bottom: 50px;
}

.faq .row {
  margin-bottom: 30px;
  text-align: left;
  padding: 10px 25px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transition: 0.2s;
}

.faq .row:hover{
  background-color: #072A40;
  color: white;
}

.faq .row h3 {
  font-size: 25px;
  margin: 10px auto;
}
.faq .row p {
  margin-bottom: 20px;
}



/* ======= LOGIN PAGE ======= */
.wrapper-login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 80vh;
  background-color: aliceblue;
  margin-top: -10px;
}

.wrapper-login .login-form {
  background-color: white;
  width: 50%;
  height: 30vw;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.login-form h2 {
  font-size: 40px;
  color: #0089BF;
  margin-top: 10%;
  margin-bottom: 40px;
}

.login-form input {
  display: block;
  width: 50%;
  height: 40px;
  margin: auto;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 10px;
  font-size: 20px;
  margin-bottom: 30px;
  outline: none;
}


.login-form .googleSignIn {
  width: 50%;
  height: 50px;
  display: grid;
  margin:  30px auto;
  grid-template-columns: 50px auto;
  /* border: 1px solid lightgray; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 20px;
  align-items: center;
  color: gray;
  padding: 0  10px;
}
.login-form .googleSignIn:hover {
  background-color: #0089BF;
  color: white;
}
.login-form .googleSignIn img{
  height: 35px;
  width: 35px;
  object-fit: cover;
  background-color: white;
  padding: 3px;
  border-radius: 10px;
}

.login-form .googleSignIn p{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-left: -50px;
}

.login-form button {
  display: block;
  width: 30%;
  height: 40px;
  background-color: #0089BF;
  color: white;
  margin:20px auto;
  border-radius: 10px;
  border: 1px solid transparent;
  font-size: 18px;
}
.login-form button:hover {
  border: 1px solid #0089BF;
  color: #0089BF;
  background-color: white;
}







.myReservations {
  /* z-index: 1;
  width: 70%;
  max-width: 1000px;
  height: fit-content;
  max-height: 70vh;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
  padding: 30px 30px;
  color: white;

  background-color: rgba(255, 255, 255, 0.492);
  transition: 2s;
  overflow-y: scroll; */
  /* background-color: rgba(255, 255, 255, 0.292); */
  width: 100%;
  padding: 60px 30px;
  background-color: white;
}
.reservations-content {
  width: 100%;
}



.myReservations .sidebar {
  border-right: 1px solid lightgray;
  padding-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; 
}

.myReservations .iconProfile {
  font-size: 60px;
  color: gray;
}
.myReservations .sidebar #name {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
}
.myReservations .sidebar #name p {
  text-align: left;
  font-size: 25px;
  text-transform: capitalize;
  font-weight: bold;
  color: gray;
  line-height: 30px;
}

.myReservations .row-reservation {
  margin: 20px auto;
  border-radius: 1%;
  width: 80%;
  border:1px solid lightgrey;
  padding: 40px;
  margin-bottom: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.myReservations .row-reservation .cover img{
  width: calc(100% + 80px);
  height: 400px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
  position: relative;
  top: -40px;
  left: -40px;
}

.myReservations .row-reservation .text-over {
  position:relative;
  top: -300px;
  left: -40px;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
}

.myReservations .row-reservation  .text-over #nickname {
  font-size: 60px;
  font-weight: bold;
  color: white;
  text-align: left;
  margin-left: 30px;
}
.myReservations .row-reservation .text-over #title {
  font-size: 30px;
  font-weight: bold;
  color: white;
  text-align: left;
  margin-left: 30px;
}

.myReservations .two-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.myReservations .firstRow {
  margin-top: -150px;
}

.myReservations .three-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}


.myReservations .rooms {
  width: 90%;
  margin: auto;
  color: gray;
}

.myReservations .room {
  display: flex;
  flex-direction: column;
}

.myReservations .room img {
  display: block;
  width: 40px;
  height: 40px;
  margin: auto;
  margin-bottom: 5px;
}



.iconAddress {
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #0089BF;
}

.reservations-content #address {
  text-align: left;
  font-size: 20px;
  color: gray;
  margin-bottom: 10px;
}
.reservations-content a {
  color: gray;
  padding-bottom: 2px;
  border-bottom: 1px solid gray;
}

.row-reservation .dates {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 40px;
  margin-bottom: 40px;
}

.row-reservation .dates .iconCalendar{
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #0089BF;
}

.row-reservation .dates p {
  font-size: 20px;
  color: gray;
  text-align: left;
}


.houseDescription {
  text-align: left;
  margin-top: 30px;
}
.houseDescription #title {
  font-size: 25px;
  font-weight: bold;
  color: #0089BF;
}

.row-reservation .amenities {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
  padding: 0;
}
.row-reservation .amenitie {
  text-align: left;
  margin-right: 30px;
  font-size: 18px !important; 
  transition: 0.5s;
}

.row-reservation .amenitie:hover {
  color: #0089BF;
  font-weight: bold;
  padding-left: 10px;
}

.iconAmenities {
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #0089BF;
}




.myReservations-title {
  width: 100vw;
  color: white;
  background-color: #072A40;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -60px;
  padding: 70px 50px;
  text-align: left;
}
.myReservations-title h2{
  font-size: 40px;
}
.myReservations-title p {
  font-size:30px;
}

.myReservations hr {
  margin: 20px 0;
  background-color: white;
  height: 3px;
  border: none;
}
.myReservations h2 {
  margin-bottom: 10px;
  text-align: left;
}


.reservation-row {
  display: grid;
  grid-template-columns: 280px auto 250px;
  gap: 20px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  height: 310px;
  color: gray;
  text-align: left;
  border: 1px solid lightgray;
  align-items: center;
}
.reservation-row img {
  width: 100%;
  height:300px;
  object-fit: cover;
  border-radius: 5px;
}

.btnPrint{
  position: fixed;
  right: 15px;
  bottom: 100px;
  background-color: #072A40;
  color: white;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  font-size: 30px;
  border: none;
  z-index: 997;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.btnChat {
  position: fixed;
  right: 15px;
  bottom: 15px;
  background-color: #072A40;
  color: white;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  font-size: 30px;
  border: none;
  z-index: 999;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.btnPrint:hover, .btnChat:hover{
  background-color: #0089BF;
}

.chat-popup {
  position: fixed;
  bottom: 60px;
  right: 50px;
  width: 400px;
  height: 60vh;
  border: none;
  border-radius: 15px;
  z-index: 998;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}




/* ==========  SUPPORT PAGE  ==========  */


.question-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
  background-color: #072A40;
  margin: -10px auto 20px auto;
}
.question-container h2 {
  color: white;
  font-size: 40px;
  margin-bottom: 40px;
}
.field-question {
  width: 600px;
  display: grid;
  grid-template-columns: 80% auto;

}
.field-question input {
  width: 100%;
  height: 45px;
  border: 1px solid white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 15px;
  font-size: 15px;
  outline: none;
}

.field-question button {
  height: 45px;
  background-color: #0089BF;
  color: white;
  border: 1px solid white;
  font-size: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.questions {
  margin: 50px auto;
  max-width: 1200px;
}
.question {
  margin: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.question h3 {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
}
.question p {
  text-align: left;
  color: gray;
}

.questionConfirmation {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  opacity: 0; /* Initially set the opacity to 0 */
  animation: fadeIn 0.2s ease-in forwards; /* Apply the fade-in animation */
}


@keyframes fadeIn {
  from {
    opacity: 0; /* Start with opacity 0 */
  }
  to {
    opacity: 1; /* End with opacity 1 */
  }
}

.questionConfirmation .iconCheck{
  margin-top: -150px;
}
.questionConfirmation h2 {
  font-size: 40px;
  color: gray;
}
.questionConfirmation p {
  font-size: 30px;
  color: gray;
  font-weight: 400;
  max-width: 500px;
}


.wrapper-termsAndConditions {
  padding: 20px 60px;
  margin-bottom: 100px;
}
.wrapper-termsAndConditions h1 {
  text-align: left;
  font-size: 50px;
  color: #0089BF;
}
.wrapper-termsAndConditions h2 {
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: left;
}
.wrapper-termsAndConditions p {
  text-align: left;
  margin-bottom: 20px;
}


/* ==== RESERVATION PAGE  ===== */ 
.wrapper-reservation {
}
.wrapper-reservation .content {
  max-width: 1300px;
  margin: auto; 
}
.wrapper-reservation .coverImg {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}
.wrapper-reservation h1 {
  margin-top: -190px;
  text-align: left;
  padding-left: 20px;
  font-size: 70px;
  color: white;text-shadow: black 1px 0 10px;
}
.wrapper-reservation h2 {
  text-align: left;
  padding-left: 20px;
  font-size: 40px;
  color: white;
  color: white;text-shadow: black 1px 0 10px;
  margin-bottom: 50px;
}

.wrapper-reservation #greet {
  font-size: 40px;
  font-weight: 600;
  text-align: left;
  padding-left: 20px;
  color: #096387;

  text-transform:capitalize;
}


.wrapper-reservation .two-col {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-left: 20px;
}
.wrapper-reservation .firstRow {
  margin-top: 20px;
}
.wrapper-reservation .firstRow #address, .wrapper-reservation .firstRow a{
  text-align: left;
  margin-top: 5px;
  font-size: 20px;
  color: gray;
}

.wrapper-reservation .three-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
}


.wrapper-reservation .rooms {
  width: fit-content;
  margin: auto;
  color: gray;
}

.wrapper-reservation .room {
  display: flex;
  flex-direction: column;
}

.wrapper-reservation .room img {
  display: block;
  width: 40px;
  height: 40px;
  margin: auto;
  margin-bottom: 5px;
}



.iconAddress {
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #0089BF;
}


.wrapper-reservation .dates {
  display: flex;
  justify-content: space-between;
  margin: 40px 20px;
}

.wrapper-reservation .dates .iconCalendar{
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #0089BF;
}

.wrapper-reservation .dates p {
  font-size: 20px;
  color: gray;
  text-align: left;
}


.wrapper-reservation .amenities {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin: 30px 20px;
  gap: 10px;
}
.wrapper-reservation .amenitie {
  text-align: left;
  font-size: 15px !important; 
  transition: 0.5s;
  margin-bottom: 10px;
  text-align: center;
  color: gray;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.wrapper-reservation .amenitie:hover {
  color: #0089BF;
  font-weight: bold;
  padding-left: 10px;
}

.iconAmenities {
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #0089BF;
  margin-left: 20px;
  margin-top: 40px;
}

.wrapper-reservation iframe{
   display: none;
}

/* ====== PRINTABLE RESERVATION ======  */

.wrapper-printableReservation{
  margin-top: -100px;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}

.wrapper-printableReservation .header {
  display: grid;
  grid-template-columns: 200px auto;
  padding-bottom: 20px;
  border-bottom: 2px solid lightgray;
}
.wrapper-printableReservation .header img {
  width: 200px;
}
.wrapper-printableReservation h1 {
  text-align: left;
  margin-top: 20px;
  font-size: 25px;
  font-weight: 600;
}
.wrapper-printableReservation .bookingDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.wrapper-printableReservation .bookingDetails #left {
  text-align: left;
}
.wrapper-printableReservation .bookingDetails #right {
  text-align: right;
}
.wrapper-printableReservation .reservationDetails {
  display: grid;
  grid-template-columns: auto 40%;
  gap: 30px;
  margin-top: 30px;
}

.wrapper-printableReservation .reservationDetails .container1 {
  border: 1px solid gray;
  margin-bottom: 30px;
  padding: 30px;
}

.wrapper-printableReservation .reservationDetails .container1 .dates{
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}
.wrapper-printableReservation .reservationDetails .container1 .dates .chev{
    font-size: 30px;
}

.wrapper-printableReservation .reservationDetails .container1 hr {
  margin: 25px 0;
}

.wrapper-printableReservation .reservationDetails .container1 #description {
  text-align: left;
  margin-bottom: 30px;
}
.wrapper-printableReservation .reservationDetails .container1 .hosted {
  text-align: left;
  font-size: 15px;
  margin-bottom: 10px;
}

.wrapper-printableReservation .reservationDetails .container2 {
  border: 1px solid gray;
  margin-bottom: 30px;
  padding: 30px;
}
.wrapper-printableReservation .reservationDetails .container2 h2 {
  text-align: left;
  margin-bottom: 30px;
}
.wrapper-printableReservation .reservationDetails .container2 p {
  text-align: left;
  font-size: 15px;
  margin-bottom: 20px;
}

.wrapper-printableReservation .reservationDetails .container2 #total  {
  font-size: 20px;
  margin-top: 20px;
}

.wrapper-printableReservation .reservationDetails .container3 {
  border: 1px solid gray;
  margin-bottom: 30px;
  padding: 30px;
}
.wrapper-printableReservation .reservationDetails .container3 h2 {
  text-align: left;
  margin-bottom: 30px;
}
.wrapper-printableReservation .reservationDetails .container3 p {
  text-align: left;
  font-size: 15px;
  margin-bottom: 20px;
}

.wrapper-printableReservation .reservationDetails .container3 #total  {
  font-size: 25px;
  margin-top: 20px;
}





/* TABLET STYLES  */

@media screen and (max-width:970px){
  .content {
    padding-top: 70px;
  }
  .logo-header {
    display: none !important;
  }
  .wrapper-header {
    flex-direction: column;
    justify-content: center;
    height: fit-content;
  }
  .wrapper-header .menu {
    display: none;
  }
  .wrapper-header img {
    /* height: 50px; */
    width: 200px;
    margin: 15px auto 15px 5px;
    display: inline-block;
  }
  .wrapper-header .mobileMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    /* display: none; */
  }
  
  .wrapper-header .mobileMenu a, .wrapper-header .mobileMenu button {
    width: 100%;
    height: 40px;
    margin-bottom: 15px;
  }
  .mobile-header {
    width: 100vw;
    padding: 0 10px;
    display: grid;
    grid-template-columns: auto 40px;
    align-items: center;
    background-color: white;
    z-index: 999;
  }
  .btnMobileMenu {
    display: block;
  }


  .wrapper-loader {
    padding: 50% 20px;
  }
  .wrapper-loader .calendarIcon {
    font-size: 100px;
  }
  .wrapper-loader h2 {
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .wrapper-loader .loading-bar {
    margin-top: 50px;
    width: 85%;
  }


  .wrapper-footer .menu{
    width: 100%;
  }
  .menu a {
    font-weight: 400 !important;
    font-size: 15px !important;
  }


  .wrapper-hero {
      width: 95vw;
      height: 450px;
  }
  .hero-content {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-text {
    width: fit-content;
    height: 150px;
    /* border: 1px solid white; */
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  .hero-content h2 {
    display: block;
    margin: auto;
    margin-bottom:0;
    width: fit-content;
    width: 95vw;
    text-align: center;
    font-size: 1.8em;
  }
  .hero-content #location {
    display: block;
    margin:  10px auto;
    /* border: 1px solid white; */
    text-align: center;
  }
  .wrapper-hero button {
    font-size: 18px;
    max-width: 300px;
    height: 40px;
    margin: 10px auto 0 auto;
  }

  .hero-content p {
    font-size: 20px;
  }
  .hero-content .galery {
    display: none;
  }

  .initialLoader {
    width: 100%;
    height: 100vh;
    border: 1px solid red;
  }







  .title-properties {
    width: 100%;
    padding: 0 5%;
    margin-bottom: 10px;
    /* margin-top: 350px; */
  }
  .title-properties h2 {
    font-size: 25px;
  }

  .carousel {
    width: 100%;
  }

  .wrapper-information {
    display: block;
  }
 
  .wrapper-information h2 {
    font-size: 40px;
    text-align: center;
    line-height: 40px;
  }
  .wrapper-information p {
    font-size: 20px;  
    margin-top: 30px; 
    text-align: center;
  }
  .wrapper-information img {
    display: none;
  }
  .wrapper-information button {
    margin: 20px auto;
    width: 50%;
  }

  .wrapper-reviews {
    padding: 70px 40px;
  }

  .wrapper-reviews  .two-col{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .wrapper-reviews .two-col h3 {
    text-align: center;
    font-size: 30px;
  }
  .wrapper-reviews .two-col p {
    text-align: center;
    font-size: 20px;
  }
  .wrapper-reviews .three-col {
    overflow-x: scroll;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    padding: 0 20px;
  }
  .wrapper-reviews .review {
    margin-bottom: 30px;
    min-width: 400px;
  }
  .review .comment {
    height: 150px !important;
  }

  .wrapper-whyRecommend {
    display: flex;
    flex-direction: column;
  }
  .wrapper-whyRecommend h2 {
    margin-bottom: 50px;
    text-align: center;
    width: 115%;
    margin-left: -7.5%;
  }
  .wrapper-whyRecommend h3 {
    font-size: 30px;
    text-align: center;
    margin-top: 50px;
  }
  .wrapper-whyRecommend p {
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
  }
  .wrapper-whyRecommend img {
    width: 100%;
    height: 300px;
    margin-top: 50px;
  }


  .wrapper-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .wrapper-footer img {
    display: block;
    margin: auto;
    margin-bottom: 30px;
  }
  
  .wrapper-footer #left p {
   text-align: center; 
  }

  .wrapper-footer #footer-legend {
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }
  .wrapper-footer .newsLetter {
    margin-top: 30px;
  }
  .wrapper-footer #noSpam {
    text-align: center;
  }

  .wrapper-footer a {
    text-align: center;
  }

  .filter {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .filter select {
    width: fit-content;
    margin: 20px auto;
    text-align: center;
  }

  .wrapper-properties {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-bottom: 50px;
  }
  .property {
    margin: 5px;
    width: 330px;
    height: 510px;
  }
  .property .nickname {
    font-size: 20px;
    line-height: 25px;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .wrapper-services {
    width: 100%;
    padding: 20px;
  }
  .wrapper-services #left {
    text-align: center !important;
    max-width: 500px;
  }
  .wrapper-services .introduction h2 {
    margin: 10px auto;
  }

  .wrapper-services button {
    font-size: 18px;
  }


  .wrapper-services .introduction {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .wrapper-services .introduction img {
    width: 50%;
    height: fit-content;
    margin: 60px auto 20px auto;
  }

  .whyChooseUs {
    margin-bottom: 50px;
  }

  .wrapper-services .highlights {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .wrapper-services .highlights .container {
    width: 310px;
  }
  .wrapper-services .highlights  h2 {
    margin-bottom: 5px;
  }
  .wrapper-services .highlights  p {
    font-size: 15px;
  }



  .insights {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .insights .container {
    width: 310px;
    box-sizing: border-box;
    padding: 40px 20px; 
    border-radius: 10px;
    margin: 10px 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .insights .container p {
    margin-top: 15px;
    font-size: 18px;
  }
  .results {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .results #left {
    margin: auto;
  }
  .results #left, .results #right {
    width: 100%;
    padding: 10px;
  }
  .results img {
    display: block;
    width: calc(100% - 10px);
    height: fit-content;
    margin: 5px;
  }
  .results .two-columns img {
    width: calc(100% - 10px);
    height: 130px;
  }
  .indicators {
    width: 100% !important;
    margin: 10px;
  }

  .contact-grid2 {
    display: flex;
    flex-direction: column;

  }
  .contact-grid2 #form {
    width: 110%;
  }

  .contact-grid2 p {
    max-width: 100%;
  }
  .contact-grid2 form {
    width: 100%;
  }
  .contact-grid2 .grid-2 {
    display: block;
  }

  .contact-methods {
    margin-top: 100px !important;
  }
  .contact-methods .method {
    max-width: 100%;
  }

  .login-form {
    width: 100%;
    height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 30px 40px 300px 40px;
  }
  .login-form .two-col {
    display: block;
  }

  .login-form h2 {
    font-size: 30px;
    margin-bottom: 0;
  }
  .login-form .in-form {
    display: block;
    margin-bottom: 0px;
    border-radius: 0;
    max-width: 300px;
  }
  .login-form button {
    max-width: 300px;
  }

  .myReservations-title h2 {
    text-align: center;
    font-size: 30px;
  }  
  .myReservations-title p {
    text-align: center;
    font-size: 20px;
  }
  .reservations-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 110%;
    margin-left: -5%;
  }

  .myReservations .reservation-row {
    display: inline-flex;
    flex-direction: column;
    width: 310px;
    margin: 10px;
    height: fit-content;
    border-radius: 10px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 40px;
    padding: 10px;
  }



  .reservation-row #nickname {
    margin-top: -20px;
  }
  .reservation-row .check {
    padding: 0;
    margin-top: 15px;
    grid-template-columns: 90px auto;
    gap: 0px;
    border: none;
    width: 100%;
    border-radius: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid lightgray;
  }
  .reservation-row .icon {
    font-size: 30px;
    margin-left: 30px !important;
  }
  .reservation-row #address {
    margin-top: 20px;
  }
  .reservation-row #id {
    text-align: center;
  }

  .reservation-row .addCalendarIcon {
    display: none;
  }

  .reservation-row img {
    width: 290px;
    max-height: 150px;
  }


  .form {
    max-width: 450px;
  }
  .btnForm {
    max-width: 450px;
  }
  .wrapper-booking {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .wrapper-booking #left {
    border-right: none;
    padding: 20px;
  }
  .wrapper-booking .pictures {
    display: flex;
    flex-direction: column;
  }
  .wrapper-booking #mainPicture {
    height: 500px;
  }
  .wrapper-booking .galery {
    max-width: 100%;
    height: 110px;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: scroll;
    margin-top: -30px;
  }

  .wrapper-booking .galery img{
    margin-right: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;  
  }

  .wrapper-booking .nickname {
    font-size: 25px !important;
  }
  .wrapper-booking #left p {
    font-size: 15px;
  }
  .wrapper-booking #right {
    width: 100%;
    padding: 0;
  }
  .wrapper-booking #right h3 {
    font-size: 20px;
    text-align: center;
    color: #0089BF;
  }
  .wrapper-booking .form input {
    padding: 8px 20px;
    font-size: 15px;
  }
  .wrapper-booking .btnForm {
    font-size: 20px;
    width: 80% !important;
    height: 45px;
  }

  .confirmationScreen p {
    font-size: 18px;
    width: 80%;
  }
  .calendarCheckIcon {
    margin-top: -100px;
    margin-bottom: 30px;
  }


  .react-calendar {
    min-width: 350px;
    width: 360px;
    max-width: 360px;
  }
  .react-calendar button {
    color: gray;
  }
  .react-calendar__tile--active {
    background: #0089BF;
    color: white !important;
    border: 1px solid #0089BF;
  }
  .react-calendar__tile {
    max-width: 40px;
  }
  abbr[title] {
    text-decoration: none;
  }
  .react-calendar__month-view__weekdays{
    max-width: 97%;
  }
 

  .wrapper-booking .form {
    width: 90%;
    margin: auto;
    margin-bottom: 40px;
  }

  .wrapper-confirmation {
    width: 100%;
    top: 65px;
  }
  .wrapper-confirmation h1 {
    font-size: 30px;
    text-align: center;

  }
  .wrapper-confirmation h2 {
    text-align: center;
  }
  .wrapper-confirmation h3 {
    font-size: 15px !important;
  }
  .wrapper-confirmation .btnBack {
    font-size: 20px;
    color: #0089BF;
    border: none;
    margin-bottom: 10px;
    background-color: transparent;
    display: block;
  }
  .wrapper-confirmation .detail {
    width: 100%;
  }

  .paymentConfirmation {
    width: 100%;
    top: 65px;
  }
  .btnForm {
    display: block;
    margin: 50px auto;
    width: 90% !important;
  }
  .wrapper-loader {
    top: 0px;
  }
  .loading-bar {
    width: 50% !important;
  }
  .wrapper-loader h2 {
    margin-bottom: 40px;
  }
  .arrows {
    display: none;
  }




  .allAmenities {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 30px;
}
.amenities {
  font-size: 15px;
  color: #0089BF;
  padding: 4px 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  width: fit-content;
  margin-right: 5px;
  text-align: left;
}


.amenitiesIcon, .amenities p {
display: inline;
}
.amenities p {
  color: gray;
}



.question-container h2 {
  font-size: 25px;
}
.field-question {
  display: block;
  max-width: 90%;
}
.field-question input, .field-question button {
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
}
.field-question button {
  border: none;
  width: 60%;
  margin-top: 20px;
}

.question h3 {
  font-size: 25px;
  margin-bottom: 10px;
}

.questionConfirmation h2 {
  font-size: 25px;
}
.questionConfirmation p {
  font-size: 18px;
  max-width: 70%;
  margin-top: 10px;
}




}



/* PHONE STYLES */
@media screen and (max-width:500px){
  .wrapper-footer .menu{
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }

  .hero-text {
    height: 200px;
  }
  .wrapper-hero button {
    margin-top: 30px;
    max-width: 200px;
  }

  .wrapper-information {
    display: block;
  }
  .wrapper-information img {
    display: block;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
  .wrapper-information h2, .wrapper-information p {
    width: 120%;
    margin-left: -10%;
  }
  .wrapper-information button {
    margin: 20px auto;
    width: 70%;
  }


  .wrapper-reviews .review {
    min-width: 330px;
  }
  .wrapper-services .introduction img {
    width: 80%;
    height: fit-content;
    margin: 60px auto 20px auto;
  }
  .wrapper-services .highlights .container {
    width: 350px;
  }

  .hereForYou .method {
    font-size: 18px;
    padding: 0 20px;
  }

  .login-form button {
    width: 300px;
  }
  .wrapper-loader h2 {
    margin-bottom: 0px;
  }
  .loading-bar {
    margin-top: 40px;
    width: 70% !important;
  }

  .wrapper-termsAndConditions {
    padding: 10px 20px;
    margin-bottom: 100px;
  }
  .wrapper-termsAndConditions h1 {
    font-size: 40px;
    line-height: 40px;
    margin-top: 30px;
  }
  .wrapper-termsAndConditions h2 {
    margin-top: 40px;
    margin-bottom: 10px;
    text-align: left;
  }
  .wrapper-termsAndConditions p {
    text-align: left;
    margin-bottom: 20px;
  }




  /* ======  CHAT PAGE =====  */
  .wrapper-chat {
    min-height: calc(100vh - 190px);
    width: 100vw;
    background-color: white;
    touch-action: manipulation;
  }

  .wrapper-chat .messages {
    width: 100vw;
    height: 65vh !important; 
  }


  .wrapper-chat .new-message {
    width: 100%;
    height: 100px;
    background-color: white;
  }
  .wrapper-chat .arrowIcon {
    bottom: 50px;
  }

  /* ===== LOGIN PAGE ===== */
  .wrapper-login {
    padding: 0;
    height: 70vh;
  }
  .wrapper-login .login-form {
    width: 100%;
    height: 100%;
    padding: 20px;
  }
  .wrapper-login .login-form h2 {
    width: 80%;
    margin: 40px auto;
    text-align: center;
    margin-bottom: 40px;
  }
  .wrapper-login .login-form input {
    width: 90%;
  }
  .login-form .googleSignIn {
    width: 90%;
    height: 45px;
    border: 1px solid lightgray;
  }
  .login-form .googleSignIn img{
    height: 30px;
    width: 30px;
  }


  /* RESERVATION PAGE */
  .wrapper-reservation .content {
    max-width: 95%;
    margin: auto; 
    padding-top: 10px;
  }
  .wrapper-reservation h1 {
    margin-top: -200px;
    font-size: 40px;
    text-align: center;
    padding-left: 0;
  }
  .wrapper-reservation h2 {
    font-size: 25px;
    margin-bottom: 80px;
    text-align: center;
    padding-left: 0;
  }

  .wrapper-reservation #greet {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
  }

  .wrapper-reservation .two-col {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    padding-left: 0;
  }
  .wrapper-reservation .firstRow #address, .wrapper-reservation .firstRow a, .firstRow #iconAddress{
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .wrapper-reservation .three-col {
    margin-top: 10px;
    text-align: center;
  }
  .wrapper-reservation .dates {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 20px;
  }
  .wrapper-reservation .dates p{
    text-align: center;
  }

  .iconAddress, .iconAmenities {
    justify-content: center;
  }
  .wrapper-reservation .dates .iconCalendar{
    justify-content: center;
  }
  .wrapper-reservation .amenities {
    margin:  20px 0;
    grid-template-columns: 1fr 1fr;
  }


  .wrapper-booking {
    padding-bottom: 100px;
  }
  .wrapper-booking .googleSignIn {
    width: 90%;
    height: 45px;
    border: 1px solid lightgray;
    margin: auto;
  }

  .paymentLoader {
    width: 100vw;
    top: 70px;
    left: 0;
  }
}

/*  ======== CHAT PAGE  ========  */

.wrapper-chat {
  height: 100% !important;
  margin-top: 5px;
}

.wrapper-chat .messages {
  display: block;
  height: 50vh;
  overflow-y: auto;
  padding-top: 20px;
}
.wrapper-chat .noHeader {
  display: block;
  height: 70vh;
  overflow-y: auto;
  margin-top: -15px;
  background-color: aliceblue;
  padding-top: 10px;
}


.wrapper-chat .topNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #072A40;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-size: 20px;
  gap: 10px;
}


.wrapper-chat .messages {
  display: block;
  height: 80%;
  overflow-y: auto;
  padding-top: 20px;
}

.wrapper-chat .admin-buble {
  text-align: right;
  width: fit-content;
  max-width: 70%;
  margin: 3px 10px 0px auto;
  padding: 10px 15px;
  color: white;
  background-color: #0089BF;
  border-radius: 10px;
}

.adminTail {
  display: block;
  width: 15px;
  margin: -4px 13px 3px auto;
}
.customerTail {
  display: block;
  width: 15px;
  margin: -4px auto 3px 15px;
}


.wrapper-chat .customer-buble {
  text-align: left;
  max-width: 70%;
  width: fit-content;
  margin: 3px auto 3px 10px;
  padding: 10px 15px;
  color: white;
  background-color: #25c625;
  border-radius: 10px;
}



.wrapper-chat .message-hour {
  color: gray !important;
  margin-top: -12px;
  margin-bottom: 8px;
  font-size: 12px;
}

.admin-hour {
  text-align: left;
  margin-left: 30px;
}
.customer-hour {
  text-align: right;
  margin-right: 25px;
}

.new-message {
  display: grid;
  grid-template-columns: auto 30px;
  align-items: center;
}

.new-message textarea {
  width: 95%;
  height: 70%;
  resize: none;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  border: 1px solid lightgray;
  /* margin-top: 10px; */
  margin-left: 10px;
  touch-action: none;
}

.new-message .arrowIcon {
  font-size: 25px;
  color: #0089BF;
  margin-right: 5px;
}

.disable-zoom-textarea {
  touch-action: manipulation;
}





/* ===== NEW CODE ===== */

/* 
.wrapper-slider {
  max-width: 85%;
  margin: 30px auto;
}
.wrapper-slider .slide {

  transition: background-image 1s ease-in-out;
}

.wrapper-slider .slide .cover { 
  width: 100%;
  height: 70vh;
  object-fit: cover;
  border-radius: 20px;
} 

.wrapper-slider .slide .details {
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  max-width: 80%;
}

.wrapper-slider .slide .details h2 {
  font-size: 0vw;
  text-align: left;
}

.wrapper-slider .slide .location {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 20px;
}

.wrapper-slider .slide .hero-content {
  display: grid;
  grid-template-columns: auto 100px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.33);
}

.wrapper-slider .slide .hero-content img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 5px solid white;
  border-radius: 10px;
}
.wrapper-slider .slide .hero-content img :hover {
  transform: scale(1.2);
} 
*/


.loader {
  width: 300px;
  height: 15px;
  border-radius: 20px;
  color: white;
  border: 2px solid;
  position: relative;
  animation: fadeIn 2s ease-in-out;
}
.loader::before {
  content: "";
  position: absolute;
  margin: 2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: currentColor;
  animation: l6 3s infinite;
}
@keyframes l6 {
    100% {inset:0}
}

.cover-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #0089BF;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .cover-loader img { 
    margin-bottom: 20px;
    width: 300px;
    max-width: 70%;
    animation: fadeIn 2s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
